.footer-small{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  &__realization{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
      transition: color .3s ease-in-out;
    }
    &:hover span{
      color: $color-main;
    }
    img{
      margin-left: 15px;
      max-width: 100px;
      margin-bottom: 3px;
    }
  }

  @include mq($until: xs) {
    flex-wrap: wrap;
    
    * {
      font-size: rem(14px);
    }

    &__realization {
      width: 100%;
      margin: 5px auto 0;
    }

    img {
      max-width: 60px;
    }
  }
}


.footer {
  height: calc(100vh + 220px);
  min-height: 650px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: $mt-xl;

  &__large-logo {
    width: 25vw;
    margin-top: 120px;
  }

  &__contacts {
    background: $bg-trans-rev;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }

  &__est-adr {
    width: 80%;
    display: flex;
  }

  &__localization {
    margin: 0 auto;
  }

  &__menu {
    height: fit-content;
    margin: 23px auto 0;
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 2vw;
    row-gap: 1px;

    li {
      margin: 0;
      // line-height: 1;

      a {
        font-size: rem(14px);
      }
    }

    &-wrapper {

    }

    @include mq($until: xxl) {
      column-gap: 1.5vw;
    }

    @include mq($until: 1750) {
      grid-template-columns: repeat(1, auto)
    }
  }

  &__offices {
    max-width: 850px;
    width: 100%;

    img {
      margin-right: -30px;
    }

    &-adr {
      display: flex;
      justify-content: space-between;
    }

    svg {
      fill: white;
      width: 17px;
      height: 17px;
      margin-bottom: 3px;
      margin-right: 5px;
    }
  }

  p {
    font-size: rem(14px);
    margin-bottom: 0;
  }

  h4 {
    font-size: rem(19px);
    border-bottom: 1px solid white;
    padding-bottom: 15px;
    margin-bottom: 25px;
  }

  * {
    color: white;
  }

  @include mq($until: xxl) {
    &__offices {
      max-width: 750px;
      img {
        margin-right: -15px;
      }
    }
  }

  @include mq($until: xl) {
    &__contacts {
      margin-left: -20px;
      margin-right: -20px;
    }

    h4 {
      font-size: rem(17px);
    }

    &__est-adr {
      width: 70%;

    }

    &__menu {
      li {
        a {
          font-size: rem(13px);
        }
      }
    }

    p {
      font-size: rem(13px);
    }

    &__offices {


      img {
        width: 80px;
      }
      
    }
  }

  @include mq($until: lg) {
    margin-top: $mt-lg;
    &__contacts {
      margin-left: 0;
      margin-right: 0;
    }

    
    h4 {
      font-size: rem(20px);
    }

    &__menu {
      grid-template-columns: repeat(3, auto);
      li {
        a {
          font-size: rem(16px);
        }
      }
    }

    p {
      font-size: rem(16px);
    }

    &__est-adr {
      width: 100%;
    }

    &__menu {
      margin-top: 15px;
    }

    &__contacts {
      flex-direction: column;
    }

    h4 {
      margin-bottom: 15px;
    }

    
    img {
      margin-right: -20px;
    }

    &__offices {
      margin-top: 30px;
      width: 100%;   
      max-width: unset;   
      img {
        margin-right: -15px;
        width: 120px;
      }
    }

    &__localization {
      margin-right: 0;
    }

  }

  @include mq($until: md) {
    &__offices {
      img {
        width: 80px;
      }
    }

    &__menu {
      grid-template-columns: repeat(2, auto);
    }
  }

  @include mq($until: 700px) {

    background-position: center;
    min-height: 700px;
    margin-top: $mt-sm;

        
    h4 {
      font-size: rem(24px);
    }

    &__menu {

      li {
        a {
          font-size: rem(18px);
          line-height: 1.8;
        }
      }
    }

    p {
      font-size: rem(18px);
      line-height: 1.8;
    }

    &__large-logo {
      width: 150px;
      margin-top: 40px;
    }

    &__localization {
      margin-right: auto;
    }

    &__est-adr {
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      width: fit-content;
    }

    &__menu {
      grid-template-columns: repeat(1, auto);
      margin-bottom: 30px;
    }

    &__offices {
      margin: 30px auto 0;
      max-width: 100%;
      width: 190px;
      text-align: center;
      &-adr {
        &> div {
          margin-top: 15px;
        }
        img {
          width: 100px;
          margin: 5px auto;
        }
        flex-direction: column;
      }
    }
  }
}