.subsite {

    &__top {
        
        p {
            font-size: rem(15px);
        }

        display: flex;
        margin-top: 100px;
        margin-bottom: 50px;
        justify-content: space-between;

        &> div {
            width: 47%;
        }

        h1 {
            margin-top: 50px;
        }
    }

    &__top-content {
        &> div {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    &__top-image {
        height: auto;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        p {
            font-size: rem(15px);
        }
    }

    .row {
        margin: 50px 0;
        place-items: end;
        justify-content: space-between;

        &>div {
            // max-width: 710px;
        }
    }

    &__header {
        position: absolute;
        top: 7vw;
        font-family: $beata-font;
        z-index: 1;
        color: white;
        font-size: rem(80px);
        text-shadow: 0px 3px 26px #0000009D;
    }

    .widget {
        margin-top: 100px;
    }

    @include mq($until: xl) {

        .header__logo {
            width: 140px;
        }
        
        &__top {

            &> div {
                width: 48%;
            }
        }
    }

    @include mq($until: lg) {

        .widget {
            margin-top: 70px;
        }

        .header {
            height: 70px;
        }

        .header__logo {
            width: 110px;
        }

        &__top {
            margin-top: 70px;
        }
        
        &__top-image {
            height: 50%;
            width: 35%!important;
        }

        &__top-content {
            width: 60%!important;
        }
        
    }

    @include mq($until: md) {
        
        &__top {
            flex-direction: column;
        }

        &__top-image {
            height: calc(80vh - 80px);
            width: 100%!important;
        }

        &__top-content {
            padding: 0 $padding-lg;
            width: 100%!important;
        }

        &__header {
            top: 80px;
            font-size: rem(65px);
        }
    }

    @include mq($until: sm) {

        .widget {
            height: 300px;
        }

        &__top-image {
            height: calc(65vh - 70px);
        }

        &__top-content {
            padding: 0 $padding-sm;
        }
        
    }

    @include mq($until: xs) {

        &__top-image {
            height: 60vw;
        }

        &__top-content {
            padding: 0 $padding-xs;
        }
        
    }

}