.widget {
	width: 100%;
	margin-top: $mt-xl;
	position: relative;
	overflow: hidden;

	svg {
		width: 100%;
		height: 100%;
	}

	&__floor {
		opacity: 0;
		transition: all .3s;
		cursor: pointer;

		&:hover {
			opacity: 0.5;
		}
	}

	* {
		color: white;
		font-family: $secondary-font;
		font-weight: 500;
		font-size: rem(15px);
	}

	&__total {
		font-size: 60px;
		font-weight: 600;
		line-height: 1.2;
	}

	&__house {
		border-radius: 50%;
		width: 200px;
		height: 200px;
		position: absolute;
		z-index: 5;
		background: $bg;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		top: 80px;

		&-a {
			left: 0;
		}

		&-b {
			right: 0;
		}

	}

	&__panel {
		width: 100%;
		background: $bg-trans-rev;
		padding-top: 40px;
		padding-bottom: 40px;
		position: absolute;
		bottom: 0;
		left: 0%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		select {
			margin-right: 10px;
			background-color: transparent;
			background-image: url('../../images/arrow.png');
			background-repeat: no-repeat;
			background-position: 92% 42%;
			background-size: 13px;
			// background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
			-moz-appearance: none; 
			-webkit-appearance: none; 
			appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.486);
			border-radius: 5px;
			padding: 10px 35px 10px 15px;
			font-weight: 300;
			font-family: $font-family;
			transition: all .4s;

			&:active, &:focus {
				border: 1px solid rgb(255, 255, 255);
			}
		}

		option {
			color: black;
		}

		.button {
			padding: 15px 30px;
			margin-left: 7px;

			&.active {
				color: #53575E;
				background-color: white;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.85);
				color: #23262b;
			}
		}
	}
	&__modal {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 100%;
		background-color: white;
		z-index: 10;
		transition: all .4s;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 2px solid #b2b2b2;
		border-bottom: 2px solid #b2b2b2;

		&.active {
			left: 0;
		}

		.hata {
			color: black;
		}

		.close {
			position: absolute;
			right: 52px;
			top: 52px;
			width: 32px;
			height: 32px;
			opacity: 0.3;
			transition: all .3s;
		  }
		  .close:before, .close:after {
			position: absolute;
			left: 15px;
			content: ' ';
			height: 32px;
			width: 3px;
			background-color: #474747;
		  }
		  .close:before {
			transform: rotate(45deg);
		  }
		  .close:after {
			transform: rotate(-45deg);
		  }
		  .close:hover {
			opacity: 1;
		  }
	}

	&-sub {
		// margin-top: ;
	}

	@include mq($until: xl) {
		&__panel {
			width: 100%;
			background: $bg-trans-rev;
			padding-top: 40px;
			padding-bottom: 40px;
			position: absolute;
			bottom: 0;
			left: 0%;
			display: flex;
			justify-content: space-between;
			align-items: center;
	
			select {
				margin-right: 5px;
				padding: 10px 25px 10px 10px;
			}
		}

		*:not(.widget__total) {
			font-size: rem(14px);
		}

		.button {
			padding: 15px 15px;
			margin-left: 6px;
		}

	}

	@include mq($until: lg) {
		margin-top: $mt-lg;

		&-sub {
			margin-top: 70px;
		}

		&__panel {

			padding-top: 20px;
			padding-bottom: 20px;
			align-items: flex-end;

			& > div {
				display: flex;
			}

			label {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
			}

			select {
				margin: 5px 5px 5px 0;
				padding: 10px 25px 10px 10px;
			}

			.button {
				padding: 15px;
				margin: 5px;
				width: 170px;
			}
		}

		&__house {
			top: 20px;
			width: 165px;
			height: 165px;
		}

		&__total {
			font-size: 44px;
		}
	}

	@include mq($until: md) {

		overflow: unset;
		margin-bottom: 100px;

		&-sub {
			margin-bottom: 150px;
		}
		
		&__panel {
			padding: 20px;
			top: 100%;
			bottom:unset;

			.button {
				width: auto;
				padding: 15px;
			}

			select {
				margin: 5px 10px 5px 0;
			}

			span {
				margin-left: 7px;
			}
		}

		&__house {
			top: 10px;
			width: 145px;
			height: 145px;
		}

		&__total {
			line-height: 1;
			font-size: 40px;
		}

		*:not(.widget__total) {
			font-size: rem(15px);
		}
	}

	@include mq($until: sm) {
		margin-top: $mt-sm;
		margin-bottom: 270px;
		height: 80vw;
		background-image: url(./../../images/wideget-bg.jpg);
		background-repeat: no-repeat;
		background-size: cover;

		&-sub {
			margin-bottom: 300px;
		}

		svg {
			display: none;
			cursor: auto;
		}

		&__panel {
			flex-direction: column;
			align-items: center;
			padding: 20px 30px;

			select {
				margin: 5px;
				padding: 12px 60px 12px 25px;
			}

			&> div {
				margin: 5px 0;
			}

			.button {
				width: 145px;
			}
		}

	}

	@include mq($until: xs) {

		margin-bottom: 250px;
		height: 500px;
		background-position: 10%;

		&-sub {
			margin-bottom: 300px;
		}

		*:not(.widget__total) {
			font-size: rem(16px);
		}

		&__house {
			&-a{
				top: 10%;
			}
			&-b {
				top: unset;
				bottom: 10%;
			}
		}
		
		.button {
			width: 143px;
		}
	}
}

.floor-tip, .apt-tip {
	position: absolute;
	z-index: 50;
	padding: 15px;
	background-color: rgba(0, 0, 0, 0.486);
	top: 0;
	border-radius: 5px;
	opacity: 0;
	transition: opacity .5s;
	
	* {
		font-size: rem(13px);
	}
}

.info {
	* {
		text-align: center;
		color:rgb(49, 49, 49);
	}
}