.house {

    margin-bottom: 70px;

    &__top {
        position: relative;
        height: 400px;

        h1 {
            position: absolute;
            bottom: 30px;
            color: white;
        }
    }

    &__floor, &__buildings {
        height: 200px;
        svg {
            transform: rotate(90deg);
        }
    }

    &__floor {
        margin-right: -70px;
        span {
            margin-right: -70px;
        }
    }

    &__main {
        background-color: #F1F2F2;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;
    }

    &__areas {
        width: 35%;
        max-width: 500px;  
        display: flex;
        flex-direction: column;
        justify-content: center;

        ol {
            margin-left: 0;
            li {
                border-bottom: 1px solid white;
                margin-bottom: 5px;
                padding-bottom: 10px;
                padding-left: 30px;
                position: relative;
                div {
                    display: flex;
                    justify-content: space-between;
                }
                counter-increment: li;
                &::before { 
                    content: counter(li) " ";
                    position: absolute;
                    left: 0;
                }
            }
        }

        h3 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;

            span {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        .button {
            width: 49%;
        }
    }

    &__plans {
        width: 64%;
        margin-left: 45px;

        svg {
            height: 140px;
            width: auto;
        }
    }

    &__mainplan {
        margin-top: -10vw;
        background-color: white;

        img {
            padding: 10%;
        }
    }

    &__building {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        &>div {
            display: flex;
            align-items: center;
            svg {
                margin-left: 1.5vw;
            }
        }

        span {
            font-size: .9vw;
        }
    }

    @include mq($until: xl) {
        &__areas {
            width: 44%;

            h3 {
                margin-bottom: 20px;
            }
        }

        &__buttons {
            margin-top: 20px;
        }

        &__floor {
            margin-right: -40px;
            span {
                margin-right: -40px;
            }
        }

        &__plans {
            svg {
                height: 100px;
            }
        }
    }

    @include mq($until: lg) {
        &__mainplan {
            img {
                padding: 5%;
            }
        }

        &__building {
            margin-top: 30px;
            margin-bottom: 30px;
            span {
                font-size: 1.2vw;
                margin-bottom: 20px;
            }

            &> div {
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__plans svg {
            height: 10vw;
            width: auto;
        }

        &__buttons {
            .button {
                font-size: rem(15px);
                &:first-of-type {
                    width: 53%;
                }
                &:last-of-type {
                    width: 45%;
                }
            }
        }

        &__areas {
            width: 47%;
        }

    }

    @include mq($until: md) {

        &__floor, &__buildings {
            height: 30vw;
        }

        &__floor {
            margin-right: 0;
            span {
                margin-right: 0;
            }
        }

        margin-bottom: 50px;

        &__main {
            flex-direction: column;
        }

        &__building {
            &> div svg {
                margin-left: 0;
            }
        }

        &__plans {
            margin-left: 0;
            margin-top: 40px;
            width: 100%;

            svg {
                height: 80px;
            }
        }

        &__mainplan {
            margin-top: 0;
        }

        &__areas {
            width: 100%;
            max-width: 500px;
            padding-right: $padding-xs;
        }

        &__buttons {
            .button {
                font-size: rem(16px);
            }
        }

        &__plans {
            svg {
                height: 17vw;
            }
        }

        &__building {
            span {
                font-size: rem(16px);
            }
        }
    }

    @include mq($until: xs) {

        &__floor, &__buildings {
            height: 45vw;
        }

        &__buttons {
            margin-top: 0;
            flex-direction: column;

            .button {
                margin-top: 10px;
                width: 100%!important;
            }
        }

        h3 {
            margin-bottom: 0;
        }

        &__areas {
            width: 100%;
            max-width: 100%;
        }

        &__plans {
            svg {
                height: 24vw;
            }
        }
    }
}

.hata {
    transition: all .8s;
    width: 60vw;
}

.apt {
    &:hover {
        opacity: .6;
        transition: fill .7s;
    }
}