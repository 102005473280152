.top {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    min-height: 515px;
    transition: all .5s;

    * {
        color: white;
    }

    h1 {
        font-weight: 400;
        margin-left: 20px;
        margin-right: 20px;
    }

    &__button {
        background: $bg-trans-rev;
        padding: 20px 35px;
        font-weight: 300;
        font-size: rem(18px);
        display: flex;
        align-items: center;
        margin: 5vw auto 3vw;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all .4s;

        &:hover {
            border: 1px solid white;
        }
    }

    @include mq($until: xl) {
        &__name {
            svg {
                width: 80vw;
            }
        }
    }

    @include mq($until: lg) {
        &__name {
            svg {
                width: 80vw;
            }
        }
    }

    @include mq($until: md) {

        &__button {
            margin: 5vw auto 5vw;
        }
    }

    @include mq($until: xs) {

    }
}

.arrow-down {
    border-radius: 50%;
    border: 1px solid white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-left: 20px;

    &::before {
        width: 15px;
        height: 15px;
        content: '';
        display: block;
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        transform: rotate(-45deg);
        margin-top: 7px;
    }
}

.text {
    margin-top: $mt-xl;
    display: flex;
    flex-direction: column;
    max-width: 930px;
    text-align: center;
    align-self: center;
    @include mq($until: lg) {
        margin-top: $mt-lg;
    }

    @include mq($until: sm) {
        margin-top: $mt-sm;
    }

    @include mq($until: xs) {
        text-align: justify;

        h2 {
            text-align: center;
        }
    }
}