.presentation {
	position: relative;
	margin-top: calc(115px + 2vw);

	&-type2 {
		margin-top: calc(115px + 4vw);		
	}

	&__img {
		position: absolute;
		
		&-left {
			left: 0;
			top: 10vw;
			width: 25vw;
			height: 650px;
		}

		&-right {
			top: -2vw;
			right: 0;
			width: 22vw;
			height: 600px;
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	&__container {
		border-bottom: 2px solid rgb(178, 178, 178);
		border-left: none;
		padding: 8vw 10vw 15vw 15vw;
		margin: 0 10vw;
		position: relative;
		z-index: -1;
	}

	h2 {
		margin-left: 10vw;
	}

	li {
		margin-top: 0;
	}

	.slider-arrow {
		width: 65px;
		height: 65px;
		border: none;
		border-radius: 50%;
		color: white;
		background-color: #00000085;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		z-index: 10;
		transition: all .5s;
		font-size: 23px;
		bottom: 2vw;
	  
		&:hover {
		  background-color: $color-main;
		}
	
		&_prev {
			left: calc(85% - 65px);
			padding-right: 9px;
	
		i	{
				&::before {
					background-image: url('../../images/arp.svg');
				}
			}
		}
	  
		&_next {
			right: calc(13% - 65px);
		  	padding-left: 9px;
	  
			i {
				&::before {
				background-image: url('../../images/arn.svg');
				}
			}
		}
	  
	  
		i {
		  &::before {
			content: '';
			width: 25px;
			height: 38px;
			display: block;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		  }
		}
	  }

	&__slider {
		width: 54vw;
		margin: -100px 5vw 0 auto;
		background-color: white;
		height: 35vw;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		div, a {
			height: 100%;
		}
	}
	

	&__heading {
		&-right {
			text-align: right;
		}
	}

	&-type2 {

		.presentation {
			&__heading {
				text-align: right;
				margin-right: $padding-xl*1.5;
				// border-bottom: 2px solid rgb(178, 178, 178);
				position: relative;
			}

			&__container {
				border: none;
			}

			&__img {
				&-left {
					top: -4vw;
				}

				&-right {
					top: 8vw;
					height: 800px;
				}
			}

			&__slider {
				margin: -150px auto 0 5vw;
			}
			
		}

		@include mq($until: xxl) {
			h2 {
				margin-left: 20vw;
			}

			.presentation {
				&__img-right {
					top: 10vw;
				}
			}
		}

		@include mq($until: xl) {

			.presentation {
				&__container {
					padding: 4vw 10vw 19vw 14vw;
				}
				&__img {
					&-left {
						top: -40px;
					}
	
					&-right {
						top: 140px;
						height: 800px;
					}
				}
			}
		}

		@include mq($until: md) {

			.presentation {
				
				&__container {
					margin: 0;
    				padding: 30px 10vw 50px 0;
				}
				
				&__heading {
					margin-right: 0;
				}

				&__slider {
					margin: 0;
				}

			}
			
		}

		
		@include mq($until: xs) {

			.presentation {
				
				&__container {
					padding: 30px 0;
					p {
						text-align: justify;
					}
				}
			}
		}
	}

	@include mq($until: lg) {
		margin-top: calc(90px + 2vw);

		&-type2 {
			margin-top: calc(90px + 40px);		
		}
	}
	
	@include mq($until: md) {

		margin-top: $mt-lg;

		&-type2 {
			margin-top: $mt-lg;	
		}

		&__container {
			margin: 0 10vw 0 0;
			padding: 8vw 10vw 15vw 5vw;
		}

		h2 {
			margin-left: 0;
		}

		&__img {
			display: none;
		}

		&__slider {
			width: 100%;
			margin: -50px auto 0;
			height: 60vw;
		}
	}

	@include mq($until: sm) {
		margin-top: $mt-sm;

		&-type2 {
			margin-top: $mt-sm;	
		}
	}

	@include mq($until: xs) {
		&__container {
			margin: 0 5vw 0 0;
			padding: 8vw 5vw 15vw 5vw;

			p {
				text-align: justify;
			}
		}
		&__slider {
			width: 100%;
			margin: -20px auto 0;
		}

		.slider-arrow {
			width: 45px;
			height: 45px;
			bottom: 15px;

			i {
				&:before {
					width: 15px;
					height: 28px;
				}
			}

			&_prev {
				left: calc(70% - 45px);
			}
		  
			&_next {
				right: calc(20% - 45px);
			}

		}
	}
}

.animate-border {

	&::before, &::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 2s;
	}

	&.t {
		&::before {
			width: 0;
			height: 100%;
			border-top: 2px solid rgb(178, 178, 178);
		}
	}

	&.b {
		&::before {
			width: 0;
			height: 100%;
			border-bottom: 2px solid rgb(178, 178, 178);
		}
	}

	&.l {
		&::after {
			height: 0;
			width: 100%;
			border-left: 2px solid rgb(178, 178, 178);
		}
	}

	&.r {
		&::after {
			height: 0;
			width: 100%;
			border-right: 2px solid rgb(178, 178, 178);
		}
	}

	&.anmt {
		&::before, &::after {
			height: 100%;
			width: 100%;
		}
	}

}

