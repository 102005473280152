.icons-set {
  display: grid;
  text-align: center;
  gap: 1vw;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    max-width: 100px;
    justify-self: center;

    img {
      width: 130px;
      filter: opacity(0.8) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
    }

    &::before {
      content: none;
    }

  }

  &__item-text {
      font-weight: 500;
      font-family: $secondary-font;
      font-size: rem(19px);
      width: min-intrinsic;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      display: table-caption;
      display: -ms-grid;
      -ms-grid-columns: min-content;
      text-align: center;
      margin-top: 25px;
      width: 200px;
  }

  @include mq($until: md) {
    &__item {
      img {
        width: 60px;
      }
    }
    &__item-text {
      margin-top: 15px;
      font-size: rem(17px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(15px);
      width: 120px;
    }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
      max-width: 80%;
      margin: 0 auto;
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 600px;
  background-size: cover;
  margin-top: $mt-xl;

  &__set {
    margin: 40px auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    
    &-above {

      li {
        &:nth-child(2) {
          transform: translate(-50px, -50px);
        }
        &:nth-child(3) {
          transform: translate(0, -50px);
        }
      }
    }

    &-below {

      li {
        &:nth-child(1) {
          transform: translate(-50px, 0);
        }
        &:nth-child(2) {
          transform: translate(70px, 50px);
        }
        &:nth-child(3) {
          transform: translate(70px, 70px);
        }
        &:nth-child(4) {
          transform: translate(50px, 0);
        }
      }
    }
  }

  &__img {
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.4);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    // background-repeat: no-repeat;
    // background-position: center;
    padding: 30px;
  }

  &__icon {

    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 2s;

    &.hidden {
      opacity: 0;
    }

    span {
      margin-top: 15px;
      color: white;
      font-size: rem(20px);
      text-align: center;
      font-family: $secondary-font;
      line-height: 1.2;
    }
  }

  @include mq($until: xl) {
    img {
      width: 90vw;
    }

    &__img {
      width: 140px;
      height: 140px;
    }
  }

  @include mq($until: lg) {
    margin-top: $mt-lg;

    &__set {
      &-above {
  
        li {
          &:nth-child(1) {
            transform: translate(-30px, 0);
          }
          &:nth-child(2) {
            transform: translate(0, -50px);
          }
        }
      }
    }
  }

  @include mq($until: md) {
    min-height: 700px;
    img {
      width: 90vw;
    }

    &__img {
      width: 95px;
      height: 95px;
      background-size: 50%;
      padding: 20px;
    }

    &__set {
      width: 90%;
      &-below {
        li {

          &:nth-child(2) {
            transform: translate(0, 50px);
          }
        }
      }
      span {
        font-size: rem(16px);
      }
    }
  }

  @include mq($until: sm) {
    margin-top: $mt-sm;
    min-height: auto;
    height: auto;

    img {
      width: 95vw;
    }

    &__set {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 25px;

      &-above {
  
        li {
          transform: translate(0, 0)!important;

        }
      }

      &-below {
  
        li {
          transform: translate(0, 0)!important;
        }
      }
    }
  }
}