.two-columns {
    margin-top: $mt-xl;
    display: flex;

    &> div {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    &__left {
        justify-content: space-between;
        margin-top: 100px;

        &> div {
            padding: 50px 10%;
            width: 85%;
			position: relative;
            // border-right: 2px solid #b2b2b2;
        }

        img {
            width: 95%;
            height: 100%;
        }
    }

    &__right {
        place-items: flex-end;
        justify-content: space-between;
        
        &> div {
            width: 85%;
            // border-left: 2px solid #b2b2b2;
			position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 5vw;
        }

        img {
            width: 95%;
            height: 100%;
        }

        a {
            margin-top: 30px;
            font-family: $secondary-font;
        }
    }

    @include mq($until: lg) {
        margin-top: $mt-lg;
    }

    @include mq($until: md) {
        flex-direction: column;

        &> div {
            width: 100%;
        }

        &__left {
            margin-top: 0;
        }

        &__right {
            margin-top: 50px;
            
            &> div {
                padding-top: 40px;
            }
        }
    }

    @include mq($until: sm) {
        margin-top: $mt-sm;
    }

    @include mq($until: xs) {
        p {
            text-align: justify;
        }
        &__right {
            margin-top: 15px;
            &> div {
                width: 90%;
                padding-top: 30px;
            }
        }
        
        &__left {
            &> div {
                padding: 30px 15px;
                width: 90%;
            }
        }
    }
}


.chess {
	display: flex;
	padding: 0 5vw;
	flex-direction: column;
    margin-top: $mt-xl;

	p {
		line-height: 1.7;
	}

	&__img {
		height: 27vw;
		img	{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__text {
		height: 27vw;
		padding-top: 2vw;

		h3 {
			border-bottom: 2px solid #b2b2b2;
			padding-bottom: 12px;
			margin-bottom: 40px;
		}
	}

	&>div {
		width: 100%;
	}

	&__left, &__right {
		display: flex;

		&>div {
			width: 50%;
		}
	}
	&__left {
		p {
			padding-right: 3vw;
		}
	}
	&__right {

		h3 {
			text-align: right;
		}

		p {
			padding-left: 3vw;
		}
	}

	@include mq($until: xl) {
        padding: 0;
		h3 {
			margin-bottom: 30px;
		}
	}


	@include mq($until: lg) {

        margin-top: $mt-lg;

        &__img {
            height: 50vw;
        }

		&__left, &__right {
			flex-direction: column;
            p {
                padding: 0;
            }
			&>div {
				width: 100%;
			}
		}

		&__right {
			display: flex;
			flex-direction: column-reverse;
		}

		&__text {
			height: auto;
			margin: 30px auto;
		}
	}
	
    @include mq($until: sm) {
        margin-top: $mt-sm;
    }

	@include mq($until: xs) {

		&__img {
			height: 50vw;
		}

	}
}



.three-columns {

    display: flex;
    justify-content: space-between;
    margin-top: 145px;

    img {
        width: 29vw;
        height: 620px;
        object-fit: cover;
    }

    &__left {
        margin-top: 45px;
    }

    &__right {
        margin-top: -75px;
    }

    &__center {
        width: 40%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            width: 80%;
            font-size: rem(15px);
        }
    }

    h3 {
        padding-bottom: 10px;
        text-align: end;
        position: relative;
        margin-bottom: 40px;

        &:after {
            content: '';
            position: absolute;
            border-bottom: 2px solid #b2b2b2;
            width: 0;
            bottom: 0;
            z-index: -1;
			transition: all 2s;
        }

        &.decorator-left {
            &:after {
                left: 0;
            }
        }

        &.decorator-right {
            &:after {
                right: 0;
            }
        }

		&.anmt {
			&::after {
				width: 200%;
			}
		}
    }

    @include mq($until: xxl) {
        img {
            width: 29vw;
            height: 600px;
        }
    }

    @include mq($until: md) {
        flex-direction: column-reverse;
        margin-top: 75px;

        img {
            width: 100%;
            height: 60vw;
        }

        &__center {
            width: 100%;
            margin-top: 40px;
        }

        &__right {
            padding-right: $padding-lg;
            margin-top: 0;
        }
        &__left {
            margin-top: 0;
            margin-left: $padding-lg;
        }

        &:after {

        }
    }

    @include mq($until: sm) {
        &__right {
            padding-right: $padding-sm;
        }
        &__left {
            margin-left: $padding-sm;
        }
    }

    @include mq($until: xs) {
        &__right {
            padding-right: $padding-xs;
        }
        &__left {
            margin-left: $padding-xs;
        }

        &__center {
            p {
                text-align: justify;
                width: 100%;
                padding: 0 $padding-xs;
            }
        }
    }
}