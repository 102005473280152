.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100px;
  background: $bg;
  z-index: 500;

  .logo-lines {
    transition: all 1s;
  }

  &--fixed {
    position: fixed;
    width: 100%;
  }

  &__logo {
    z-index: 100;

    svg {
      position: absolute;
      top: 0;
      z-index: 100;
      transition: all .7s;
      height: auto;
    }
  }

  &__nav {
    width: 80%;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: white;
    font-size: rem(18px);
    font-weight: 300;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: rgb(194, 194, 194);
    }
  }

  @include mq($until: xxl) {
    &__menuLink {
      font-size: rem(17px);
    }
  }

  @include mq($until: xl) {

    &__menuLink {
      font-size: rem(15px);
    }

    &__nav {
      width: 84%;
    }
    
    &__logo {
      svg {
        width: 160px;
      }
    }
  }

  @include mq($until: lg) {

    &__menuLink {
      font-size: rem(13px);
    }

    &__nav {
      width: 87%;
    }
    
    &__logo {
      svg {
        width: 110px;
      }
    }
  }

  @include mq($until: md) {

    min-height: 70px;

    &__menuLink {
      font-size: rem(18px);
    }

    &__nav {
      width: 100%;
    }
    
    &__logo {
      svg {
        width: 100px;
      }
    }
  }
  
  @include mq($until: xs) {

    min-height: 60px;
    
    &__logo {
      svg {
        width: 80px;
      }
    }
  }

}