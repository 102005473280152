.separator {

	width: 100%;
	height: 2px;
	display: block;

	&-short {
		width: 94%;
		margin: 30px auto;
	}

	&-white {
		background-color: white;
	}

	&-black {

	}
}