.map {
	height: 80vh;
	margin-top: $mt-xl;

	@include mq($until: lg) {
		margin-top: $mt-lg;
	}

	@include mq($until: sm) {
		margin-top: $mt-sm;
		height: 100vh;
		min-height: 500px;
	}
}